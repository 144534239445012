import React from "react"
import {
    Button,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react'

  import B2BLogo from "@images/B2BIntegratorLogo.svg"
  import PuzzelPieces from "@images/b2bpuzzelpieces.png"
  import IntegrationCloud from "@images/B2BIntegratorCloud.png"
  import SearchIcon from "@images/search.svg"
  import AnalyticsIcon from "@images/analytics.svg"
  import TransferIcon from "@images/transfer.svg"
  import APIIcon from "@images/api.svg"
  import overview from "@images/b2boverview.svg"
  import { Link } from 'gatsby'
  import HeaderRow from "../components/headerRow.js"



const IndexPage = ({ data }) => {
    const [visible, setVisible] = React.useState(false)
    const sayHello = () => {
        setVisible(!visible);
      };

    return (
        <Grid>
      
        <Grid.Row columns={2}>
            <Grid.Column mobile={14} computer={8}>
                <Image src={B2BLogo} as='a' href='/'/>
            </Grid.Column>
            <Grid.Column only="computer" computer={8}>
                <Menu secondary>
                    <Menu.Item as={Link} name='features'  to='/features'>Features</Menu.Item>
                    <Menu.Item as={Link} name='pricing'  to='/pricing'>Pricing</Menu.Item>
                    <Menu.Item name='resources'>Resources</Menu.Item>
                    <Menu.Menu position="right">
                        <Menu.Item as={Link} name='login' to='/login'>Login</Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Grid.Column>
            <Grid.Column only="tablet mobile" mobile={2}>
                <Menu secondary floated='right' >
                    <Menu.Item >
                        <Icon onClick={() => {sayHello()}} name="sidebar"></Icon>
                    </Menu.Item>
                </Menu>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column>
                <Sidebar.Pushable as={Segment}>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    direction="right"
                    onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                    width='thin'
                    inverted
                >
                    <Menu.Item as={Link} name='features'  to='/features'>Features</Menu.Item>
                    <Menu.Item as={Link} name='pricing'  to='/pricing'>Pricing</Menu.Item>
                    <Menu.Item as='a'>Resources</Menu.Item>
                    <Menu.Item as='a'>Login</Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={visible} >
                    <Grid stackable>
                        <Grid.Row columns={2} stackable>
                            <Grid.Column>
                                <Image src={PuzzelPieces} size="huge" />
                            </Grid.Column>
                            <Grid.Column verticalAlign='middle' textAllign="center">
                                <Grid>
                                    <Grid.Row>
                                        <Segment basic >
                                            <Header size="huge">One platform to synchronize operations across customers, partners, and systems</Header>
                                            By using B2BIntegrator your business processes can operate in real time, each participant gets visibility into the process and can automate
                                            their response to exceptions speeding up the overall reaction of the entire value chain
                                        </Segment>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column textAlign='center'>
                                        <Button color='yellow' size='large' content='Black'><Button.Content style={{color:'black'}}>Start a Free Trail</Button.Content></Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>


                        {/*} <Grid.Row color="yellow" >
                            <Grid.Column textAlign="center">
                                <Image src={overview} size="huge" centered/>
                                <Segment basic>
                            <Header size="huge">One platform to synchronize operations across customers, partners, and systems</Header>
                            <Button color='black' size='large' content='Black'><Button.Content style={{color:'white'}}>Start a Free Trail</Button.Content></Button>
                            </Segment>
                            </Grid.Column>
    </Grid.Row> */}
                    </Grid>
                    <Grid columns={2} stackable>
                        <Grid.Column textAlign="center" verticalAlign='middle'>
                           <Header size="large">Integrated processes across organizational boundaries</Header>
                            <Segment basic size="big" textAlign="left">
                                Connect to your partners and customers utilizing file and API oriented integration methods.
                                Automate business processes such as <span className="ui text orange">order to cash </span> or <span className="ui text orange">procure to pay</span>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle'>
                                <Image src={IntegrationCloud} />
                        </Grid.Column>
                    </Grid>
                    <Grid rows={3}>
                        <Grid.Row color="yellow">
                            <Grid.Column verticalAlign="middle" textAlign="center">
                              <Segment basic> <Header size="big"> Use Cases for Utilizing B2BIntegrator </Header></Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row color='yellow' verticalAlign="middle" textAlign="center">
                            <Grid columns={4}>
                            <Grid.Column only="tablet mobile" >
                                    <Header as="h2" icon>
                                        <Image src={SearchIcon} />
                                        <Header.Subheader>Transaction Search</Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column only="computer" >
                                    <Header as="h2" icon>
                                        <Image src={SearchIcon} />
                                        <Header.Subheader>Transaction Search</Header.Subheader>
                                    </Header>
                                    <Segment basic style={{color:'black'}} textAlign="left" >
                                    Locate data quickly knowing only a few key words / or data fragments. Utilize webhooks to fire off events when periodic searches match transactions
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column only="mobile tablet" >
                                    <Header as="h2" icon>
                                        <Image src={AnalyticsIcon} />
                                        <Header.Subheader>Analytics</Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column only="computer" computer={4}>
                                    <Header as="h2" icon>
                                        <Image src={AnalyticsIcon} />
                                        <Header.Subheader>Analytics</Header.Subheader>
                                    </Header>
                                    <Segment basic  style={{color:'black'}} textAlign="left">
                                    Translate data into formats used by most analytical engines such as Parquet or ORC and run realtime
                                        analytics at amazing speed across all your data at the edge.  query the data efficently from redshift, snowflake or
                                        other analytical platforms.  Visualize your data using AWS quick site, or other visualization tools.
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column only="mobile tablet" >
                                    <Header as="h2" icon>
                                        <Image src={TransferIcon} />
                                        <Header.Subheader>Document Exchange</Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column only="computer">
                                    <Header as="h2" icon>
                                        <Image src={TransferIcon} />
                                        <Header.Subheader>Document Exchange</Header.Subheader>
                                    </Header>
                                    <Segment basic  style={{color:'black'}} textAlign="left">
                                     Exchange documents encoded as JSON, XML, CSV, TXT, or various EDI formats such as X12, EDIFACT, TRADECOMS.  Documents will follow a configurable
                                     lifecycle and be searchable, archived after a period of time at the end of their life cycle, and migrated to a data lake / data analytics platform
                                    </Segment>
                                </Grid.Column>

                                <Grid.Column only="mobile tablet" >
                                    <Header as="h2" icon>
                                        <Image src={APIIcon} />
                                        <Header.Subheader>Real-Time API's</Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column only="computer">
                                    <Header as="h2" icon>
                                        <Image src={APIIcon} />
                                        <Header.Subheader>Real-Time API's</Header.Subheader>
                                    </Header>
                                    <Segment basic style={{color:'black'}} textAlign="left">
                                    Manage REST and GRAPHQL based api’s including websocket based api’s to create realtime integrations between you and your partners. Easy to use dashboard keeps track of API utilization by trading partner
                                    </Segment>
                                </Grid.Column>

                            </Grid>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                            <Button color='black' size='large' ><Button.Content style={{color:'White'}}>Take a product tour</Button.Content></Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Grid.Column>
        </Grid.Row>
    </Grid>
    )
}
export default IndexPage
